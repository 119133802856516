.banner {
	position: relative;
	height: 500px;
}
.img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.container {
	width: 500px;
	position: absolute;
	bottom: 80px;
	left: 40px;
	transform: translate(0, 20%);
}
.container h2 {
	color: #fff;
	font-weight: 700;
	font-size: 30px;
	padding-bottom: 30px;
}
.overview {
	color: #fff;
	margin-top: 6px;
}

.dflex {
	width: 150px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.btn {
	padding: 0 15px;
	color: #fff;
	border-radius: 4px;
	background-color: rgba(41, 40, 40, 0.2);
	width: auto;
	height: 25px;
	border: 1px solid #ccc;
	transition: transform 0.2s;
}
.btn:hover {
	cursor: pointer;
	transform: scale(1.1);
}
