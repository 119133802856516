.container {
	position: relative;
	top: 31%;
	left: 50%;
	transform: translate(-50%, 0%);
}
.list {
	list-style: none;
	height: auto;
}
.listitem img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 0.3s;
	padding: 0 5px;
}
.image:nth-child(n + 11) {
	display: none;
}
.listitem img:hover {
	cursor: pointer;
	transform: scale(1.1);
}
.title {
	color: #fff;
	padding: 10px 20px 0;
}
.slider {
	padding: 10px 24px 46px 24px;
}
.search {
	position: relative;
	top: 2%;
	left: 50%;
	transform: translate(-50%, -0%);
}
.list_item {
	list-style: none;
	height: 270px;
}
.list_item .image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 0.3s;
	padding: 0 10px;
}
.list_item .listitem {
	height: 100%;
}
