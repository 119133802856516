.container {
	position: fixed;
	top: 50%;

	left: 50%;

	transform: translate(-50%, -10%);
	width: 100%;
	height: 450px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
	justify-items: center;
	background-color: #161515;
	margin-bottom: 30px;
}
.modal {
	padding: 40px 20px 0;
	width: 100%;
	height: 400px;
}
.modal p {
	color: #fff;
}
.name {
	width: 98%;

	height: 60px;
	border-bottom: 2px solid #beb9b9;
}
.name h4 {
	color: #fff;
	font-weight: 700;
	font-size: 30px;
}
.date {
	font-size: 18px;
	font-weight: 500;
	color: #fff;

	padding: 16px 0;
}
.video {
	padding: 40px 20px 0;
	border-radius: 8px;
	width: 100%;
	height: 400px;
}
.img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.btn {
	width: 100px;
	height: 30px;
	border: none;
	background-color: turquoise;
	position: absolute;
	top: 7%;
	right: 43.8%;
	transform: translate(-50%, -50%);
}
.btn:hover {
	cursor: pointer;
	background-color: rgb(22, 97, 89);
}
