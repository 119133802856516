.container {
	width: 100%;
	height: 1000vh;
}
.wraper {
	position: relative;
	top: 2%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 700px;
	height: 150px;
	background-color: #fff;
}
.searchinput {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 14px 20px 10px;
	border-bottom: 1px solid #00bbec;
}
.searchinput svg {
	width: 22px;
	height: 22px;
}

.searchinput input {
	width: 400px;
	height: 34px;
	caret-color: red;
	border: none;
	outline: none;
}
.container_btn {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: auto;
	padding: 30px 16px 0 0;
}
.container_btn .btn,
.btn_search {
	width: 70px;
	height: 24px;
	border: none;
	padding: 4px;
	margin: 0 4px;
	font-size: 10px;
}
.btn {
	border: none;
	background-color: transparent;
}
.btn_search {
	background-color: #00bbec;
	color: #fff;
}
.btn_search:hover {
	cursor: pointer;
	background-color: #1998b8;
}
