.list {
	list-style: none;
	height: 230px;
}
.listitem img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 0.3s;
	padding: 0 10px;
}
.image:nth-child(n + 11) {
	display: none;
}
.listitem img:hover {
	cursor: pointer;
	transform: scale(1.1);
}
